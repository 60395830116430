@import '../../_variables.scss';

.about-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    padding-top: 0;
    padding-bottom: 0;
    font-family: Arial, sans-serif;
    margin-top: 80px;
    margin-bottom: 80px;
}

.about-content {
    display: flex;
    gap: 50px;
    margin-bottom: 120px;
}

.profile-image {
    display: flex;
    flex: 1;

    img {
        margin: 0 auto;
        width: 100%;
        max-width: 470px;
        max-height: 540px;
        height: auto;
        border-radius: 100px;
        object-fit: cover;
        border: 2px solid #000000
    }
}

.about-text {
    flex: 1;

    .about-title {
        color: $primary-color;
        margin-bottom: 30px;
        @include H3;
    }

    p {
        @include caption3;
    }
}

.resume-button {
    @include caption3;
    width: fit-content;
    color: #fff;
    padding: 10px 30px 10px 30px;
    background: var(--Components-Button-background-2, #0158FF);
    text-decoration: none;
    margin-top: 30px;

    img {
        width: 24px;
        height: 24px;
    }

    &:hover {
        color: #fff;
    }

    .arrow {
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
    }
}

.skills-title {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

    h2 {
        color: $primary-color;
        @include Body2;
        margin: 0;
    }
}

.skills-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: center;
    padding: 0 2rem;
    max-width: 1100px;
}

.skill-item {
    flex: 0 1 auto;
    background-color: #fff;
    padding: 16px 32px;
    gap: 12px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    border: 2px solid #1f1f1f;
    @include caption1;

    img {
        width: 24px;
        height: 24px;
    }
}

.skill-icon {
    font-size: 1.2rem;
}

@media (max-width: 768px) {
    .about-content {
        flex-direction: column;
    }

    .skills-grid {
        gap: 1rem;
    }
}

.exp-and-work {
    max-width: 1200px;
    margin: 0 auto;
    padding: 120px 20px 40px 20px;
    display: flex;
    justify-content: center;
    gap: 100px;

    .section {
        width: 40%;
        display: flex;
        flex-direction: column;

        h4 {
            @include H4;
            color: $primary-color;
            display: flex;
            align-items: center;
            gap: 16px;
            margin: 0px 0px 28px 0px;
        }

        .item {
            margin-bottom: 40px;
            padding-bottom: 12px;
            border-bottom: 1px solid;
            width: 100%;
            max-width: 480px;

            .title {
                @include Body1;
                margin: 12px 0 12px 0;
            }

            .date-company {
                display: flex;
                justify-content: space-between;
                font-size: 14px;
                align-items: center;

                .date {
                    @include caption3;
                    padding: 3px 20px 3px 20px;
                    gap: 10px;
                    border-radius: 21px;
                    border: 1px solid #1f1f1f;
                }

                .company {
                    max-width: 200px;
                    color: #8C8C8C;
                    @include caption3_bold;
                }
            }
        }
    }
}

// Add media query for responsiveness
@media (max-width: 1100px) {
    .exp-and-work {
        flex-direction: column;

        .section {
            width: 100%;
            margin-bottom: 40px;
            .item {
                max-width: 100%;
            }
        }
    }
}