@import '../../_variables.scss';
@media (max-width: 450px) {

  .lightning-svg,
  .leaf-svg,
  .curly-arrow-svg {
    display: none;
  }
}

.contact-section {

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 120px;
  position: relative;

  background-color: $background-color;

  .title {
    @include H2;
    color: $primary-color;
    margin: 0;
  }

  .description-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    margin-top: 25px;
  }

  .description {
    @include Body2;
    max-width: 670px;
    margin: 0;
  }

  .email-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;

    .jane-button {
      @include Body2;
      padding: 15px 50px 15px 50px;
      text-decoration: none;
    }

    .arrow-down {
      color: $primary-color;
      padding-bottom: 40px;
    }

    .email {
      @include Title;
      color: #1F1F1F;
      text-decoration: none;
    }
  }

  .footer-text {
    @include caption3;
    margin-top: 100px;
    margin-bottom: 20px;
  }

  .social-icons {
    display: flex;
    gap: 36px;

    .jane-button {
      padding: 4px;
      gap: 16px;
      border-radius: 6px;
      border: 2px 0px 0px 0px;
    }

    img {
      width: 32px;
      height: 32px;
    }
  }

  .leaf-svg {
    position: absolute;
    bottom: 0;
    left: 50px;
    width: 134px;
    height: auto;
    pointer-events: none;
  }

  .title-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .lightning-svg {
    position: absolute;
    // left: calc(65% + 200px);
    right: 200px;
    top: -20px;
    width: 80px;
    height: auto;
    pointer-events: none;
  }

  .curly-arrow-svg {
    position: absolute;
    right: calc(80%);
    top: 150%;
    transform: translateY(-50%);
    width: 88px;
    height: 152px;
    pointer-events: none;
  }

}