@import '../../_variables.scss';

.project-1-container {
  background: #fff;
  @include caption3;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-1-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  background: #C9F5FF;
  width: 100%;
  padding: 60px 0px 100px 0px;
  place-items: center;

  .header-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 500px;
    justify-self: end;

    .header-title {
      @include H3;
      color: $primary-color;
    }

    .header-subtitle {
      @include caption1_bold;
    }
  }

  .header-image {
    justify-self: start;

    img {
      max-width: 484px;
      max-height: 424px;
      object-fit: cover;
      @media (max-width: 450px) {
        width: 100%;
      }
    }
  }
}

.project-1-content {
  display: flex;
  flex-direction: column;
  padding-top: 120px;
  gap: 120px;
  max-width: 1200px;
  padding-bottom: 120px;
}

.project-1-overview {
  padding-left: 120px;
  padding-right: 120px;
  @media (max-width: 450px) {
    padding-left: 25px;
    padding-right: 25px;
  }

  .title {
    @include H5;
  }

  .overview-content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;

    .overview-description {
      flex: 2;
      margin-right: 130px;
      @media (max-width: 450px) {
        margin-right: 30px;
      }

      p {
        margin-top: 24px;
        margin-bottom: 0px;
      }
    }

    .overview-details {
      flex: 1;

      .detail-title {
        @include caption1_bold;
      }

      .detail-item {
        margin-bottom: 1rem;
      }
    }
  }

  .project-goal {
    .goal-content {
      padding-top: 24px;
    }
  }
}

.project-1-process {
  background: #F7F8FA;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 100px;

  img {
    height: auto;
    display: block;
    width: 100%;
  }

  .title {
    @include H4;
    color: $primary-color;
    text-align: center;
  }
}

.project-1-details {
  padding-left: 120px;
  padding-right: 120px;
  @media (max-width: 450px) {
    padding-left: 25px;
    padding-right: 25px;
  }
  max-width: 680px;

  .discovery-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 40px;

    .discovery-icon-container {
      display: flex;
      gap: 1rem;

      .discovery-icon {
        width: 40px;
        height: 40px;
        margin-bottom: 1rem;
      }

      .discovery-title-text {
        @include H5;
        color: $primary-color;
      }
    }

  }

  .title {
    margin-top: 40px;
    margin-bottom: 20px;
    @include caption1_bold;
  }

  .problems-list {
    @include caption3;
  }

  .screenshot-container {
    max-width: 100%;
    overflow: hidden;

    .carousel {
      padding-top: 20px;
    }

    .job-search-screenshot {
      margin-top: 20px;
      width: 100%;
      height: auto;
      display: block;
    }
  }
}

.full-full-button {
  display: flex;
  justify-content: center;
  padding-top: 40px;

  .jane-button {
    background: #fff;

    &:active {
      color: $primary-color;
    }
  }

  a {
    text-decoration: none;
  }

}

.all-pages-container {
  background: #E7FBFF;
  padding-top: 0px;
}

.learnings-container {
  padding-top: 80px;
  padding-bottom: 80px;
  background: #C9F5FF;
  width: 100%;

  .learnings-content {
    max-width: 960px;
    margin: 0 auto;
  }

  .title {
    @include H5;
    padding-bottom: 20px;
  }

  .takeaways-content {
    @include caption3;
  }
}

@media (max-width: 1100px) {
  .project-1-header {
    grid-template-columns: 1fr;

    .header-content,
    .header-image {
      max-width: 100%;
      justify-self: center;
    }
  }
  .learnings-content {
    max-width: 80% !important;
  }
}