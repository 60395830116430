@import '../../_variables.scss';

@mixin padding {
    padding: 50px 50px;
    max-width: 994px;
    margin: 0 auto;
    @media (max-width: 450px) {
        padding: 25px 25px;
    }
}

.web-client-application {
    @include caption3;

    .project-header {
        @include padding;
    }

    .project-content {

        .project-title,
        .project-title-top {
            @include H3;
            color: #3863B7;
            text-align: center;
            margin-bottom: 100px;

            &.project-title-top {
                margin-bottom: 20px;
            }
        }

        .research-title {
            padding-top: 50px;
        }

        .project-subtitle {
            @include caption1_bold;
            text-align: center;
            padding-bottom: 50px;
        }

        .project-image-container {
            background: linear-gradient(180deg, #4383FF 0%, #153167 100%);
            border: 1px solid var(--Components-Shape-border, #1F1F1F);
            border-radius: 20px;
            box-shadow: 0px 4px 4px 0px #00000040;

            padding: 40px;
            padding-bottom: 0px;

            .project-image {
                width: 100%;
                height: auto;
                display: block;
            }
        }

        .project-details {
            @include padding;
            padding-top: 20px;
            display: flex;
            gap: 60px;


            .details-left {
                flex: 1;

                .detail-section {
                    margin-bottom: 8px;

                    .detail-title {
                        @include caption3;
                        padding-bottom: 4px;
                        color: #454545;
                    }

                    .detail-content {
                        @include caption1_bold;
                    }
                }

                .jane-button {
                    margin-top: 32px;
                    color: #3863B7;
                    width: 180px;
                    height: 20px;

                    &:active {
                        color: #fff;
                    }
                }
            }

            .details-right {
                flex: 2;

                .detail-title {
                    @include H5;
                }

                .detail-content-bold {
                    @include caption3_bold;
                }

                .detail-content {
                    @include caption3;
                }
            }
        }

        .project-disclaimer {
            text-align: center;
            background: #F5F5F5;
            margin-bottom: 0px;

            .container {
                max-width: 600px;
            }
            p {
                margin: 0px;
            }
        }
    }
}

.project-analysis {
    .project-sections {
        background-color: #fff;

        .project-title {
            text-align: left;
        }
    }

    .research-icon {
        width: 100%;
        height: 275px;
        margin: 0 auto;
        padding: 0px 0 100px 0;
    }

    .competitor-analysis-image {
        width: 100%;
        height: auto;
        display: block;
        margin: 0 auto;
        object-fit: contain;
        margin-bottom: 100px;
    }

    .user-group-icon {
        height: 171px;
        padding-top: 0px;
    }

    .user-persona-image {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 50px;
    }

    .smaller-image {
        max-width: 60%;
    }

    .user-persona-title {
        @include caption1_bold;
        margin-bottom: 1rem !important;
    }

    .topology {
        display: flex;
        gap: 2rem;
        margin-bottom: 50px;

        img {
            width: 50%;
            height: auto;
        }
    }

    .table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 50px;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: -0.011em;
        text-align: left;
        border-radius: 12px;

        &.comparison {
            .table-header {
                background: #E4CCFF;
            }
        }

        .table-header {
            display: flex;
            background: #BDE3FF;
            border-top-right-radius: 12px;
            border-top-left-radius: 12px;

            .header-cell {
                flex: 1;
                padding: 12px;
                font-weight: bold;
                @media (max-width: 450px) {
                    padding: 6px;
                }
            }
        }

        .table-row {
            display: flex;

            .cell {
                flex: 1;
                padding: 12px;
                border: 1px solid #ccc;
                @media (max-width: 450px) {
                    padding: 6px;
                }

                ol {
                    margin: 0;
                    padding-left: 20px;
                    @media (max-width: 450px) {
                        padding-left: 10px;
                    }
                }

            }

            .border-cell-left {
                border-bottom-left-radius: 12px;
            }

            .border-cell-right {
                border-bottom-right-radius: 12px;
            }
        }
    }
}

.project-sections {
    margin: 50px 0;
    background-color: #C9DBFF80;

    .container {
        @include padding;
    }

    .mockup-section {
        display: flex !important;
        flex-direction: column;
        align-items: center;
        margin-bottom: 0px !important;
        .jane-button {
            background-color: #fff;

            &:active {
                color: $primary-color;
            }

            &:active .arrow {
                filter: none !important;
            }
        }
    }

    .section {

        display: grid;
        grid-template-columns: 385px 1fr;
        gap: 48px;
        margin-bottom: 50px;

        .section-title {
            @include H5;
        }


        .mockup-title {
            text-align: center;
        }

        .mockup-image {
            max-width: 700px;
            width: 100%;
            height: auto;
            display: block;
            margin: 0 auto;
        }

        .section-content {
            color: #454545;
            display: flex;
            flex-direction: column;
            margin: 0px;

            p {
                margin: 0px;
                margin-bottom: 50px;
            }
        }

        .goals-list {
            list-style: none;
            padding-left: 0;
            margin: 0;

            li {
                position: relative;
                padding-left: 1.5rem;
                margin-bottom: 1rem;
                font-size: 1.1rem;
                color: #555;
                line-height: 1.6;

                &:before {
                    content: "•";
                    position: absolute;
                    left: 0;
                    color: #4A90E2;
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .project-sections {
        padding: 0 1rem;

        .section {
            grid-template-columns: 1fr;
            gap: 1rem;

            .section-title {
                font-size: 2rem;
            }

            .section-content,
            .goals-list li {
                font-size: 1rem;
            }
        }
    }
}


@media (max-width: 1024px) {
    .web-client-application {
        .project-content {
            .project-details {
                flex-direction: column-reverse;
                grid-template-columns: 1fr;
            }
        }
    }
}