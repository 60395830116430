@import '../../variables';

#animated-text-strip {
    width: 100%;
    height: 96px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    overflow: hidden;
    background-color: $primary-color;

}

#animated-text-strip .marquee {
    white-space: nowrap;
    animation: marquee 30s linear infinite;
    max-width: none;
}

@keyframes marquee {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(-100%, 0);
    }
}

.marquee {
    @include H4;
    color: #FFFFFF;
}