@import './_variables.scss';

// Add this at the top of the file, after any imports
html,
body {
  overscroll-behavior-y: none;
}

body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  margin: 0;
  padding: 0;
  background-color: $background-color;
}

.App {
  padding-top: 90px;
  display: flex;
  flex-direction: column;
}

.root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
}

// Import component styles
@import './components/Header/Header.scss';
@import './components/Introduction/Introduction.scss';
@import './components/RecentWorks/RecentWorks.scss';
@import './components/Contact/Contact.scss';

a.jane-button {
  text-decoration: none;
}

.jane-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 63px;
  padding: 10px 30px;
  border: 2px solid $primary-color;
  background: transparent;
  box-shadow: 0px 6px 0px 0px rgb(108, 158, 255);
  color: $primary-color;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
  text-align: center;
  position: relative;
  top: -6px;

  // transition: all 0.3s ease;
  @include caption3;

  &:hover {
    color: $primary-color;
    top: 0;
    transition: top 0.3s ease, box-shadow 0.3s ease;
    box-shadow: none;
  }

  &:active {
    top: 0;
    background: $primary-color;
    box-shadow: none;
    color: #fff;
  }

  .arrow {
    margin-left: 8px;
    width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &:active .arrow,
  &:active .icon {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
  }
}

.jane-button-white {
  background: #fff;

  &:active {
    color: $primary-color;
  }

  &:active .arrow,
  &:active .icon {
    filter: none !important;
  }
}