$primary-color: #0158FF;
$secondary-color: #FFDA00;
$background-color: #FFFBE6;
$desktop: 1200px;
$tablet: 990px;
$mobile: 500px;

@mixin Body2_bold {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
}

@mixin Body2 {
    /* Body2 */
    font-family: 'Hind';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 140%;
}

@mixin H2 {
    /* H2 */
    font-family: 'Kalnia';
    font-style: normal;
    font-weight: 400;
    font-size: 100px;
    line-height: 140%;
    @media (max-width: 450px) {
        font-size: 50px;
    }
}

@mixin H4 {
    //styleName: H4;
    font-family: Kalnia;
    font-size: 40px;
    font-weight: 400;
    line-height: 56px;
    letter-spacing: -0.06em;
    text-align: center;
}

@mixin H3 {
    //styleName: H3;
    font-family: 'Kalnia';
    font-size: 60px;
    font-weight: 400;
    line-height: 84px;
    letter-spacing: -0.06em;
    text-align: left;
    @media (max-width: 450px) {
        font-size: 30px;
    }
}

@mixin H5 {
    //styleName: H5;
    font-family: Kalnia;
    font-size: 32px;
    font-weight: 400;
    line-height: 44.8px;
    letter-spacing: -0.06em;
    text-align: left;
}


@mixin H6 {
    //styleName: H6;
    font-family: Kalnia;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.06em;
    text-align: left;
}


@mixin caption3 {
    font-family: Hind;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
}

@mixin caption3_bold {
    //styleName: caption3_bold;
    font-family: Hind;
    font-size: 16px;
    font-weight: 700;
    line-height: 22.4px;
    text-align: left;
}

@mixin H1_bold {
    /* H1_bold */
    font-family: 'Kalnia';
    font-style: normal;
    font-weight: 700;
    font-size: 160px;
    line-height: 140%;
    /* identical to box height, or 224px */
    letter-spacing: 0.1em;
}

@mixin Body1 {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 140%;
}

@mixin Title {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 140%;
}

@mixin caption1 {
    //styleName: caption1;
    font-family: Hind;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
}


@mixin caption1_bold {
    //styleName: caption1_bold;
    font-family: Hind;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
}