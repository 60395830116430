@import '../../_variables.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // background-color: transparent;
  backdrop-filter: blur(4px);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 16px 140px 16px 40px;

  .logo {
    cursor: pointer;
  }

  .nav-links {
    span {
      cursor: pointer;
      text-decoration: none;
      margin-left: 48px;
      color: #1f1f1f;
      @include caption1;
    }

    span.current {
      @include caption1_bold;
    }
  }
}