.carousel {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .carousel-content {
        width: 100%;
        position: relative;
    }

    .carousel-image {
        width: 100%;
        height: auto;
        display: block;
    }

    .carousel-button {
        background: rgba(0, 0, 0, 0.3);
        color: white;
        border: none;
        padding: 16px;
        font-size: 24px;
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color 0.3s;

        &:hover {
            background: rgba(0, 0, 0, 0.7);
        }

        &.prev {
            left: 10px;
        }

        &.next {
            right: 10px;
        }
    }

    .carousel-dots {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        gap: 8px;

        .carousel-dot {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            border: none;
            background: rgba(255, 255, 255, 0.5);
            cursor: pointer;
            padding: 0;

            &.active {
                background: white;
            }
        }
    }
}