@import '../../_variables.scss';

.introduction-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.introduction {
  position: relative;
  width: 100%;
  max-width: 1200px;
  padding: 130px 80px;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;

  .content {
    position: relative;
    z-index: 1;
    max-width: 706px;
  }

  .hey-there {
    margin: 0px;
    color: $primary-color;
    @include H4;
    text-align: left;
  }

  .name {
    margin: 0px;
    @include H1_bold;
    color: $primary-color;
  }

  .description {
    margin: 0px;
    color: #1F1F1F;
    @include caption1;

    .highlight {
      position: relative;
      border: none;

      &::after {
        content: '';
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 0px;
        top: -20px;
        height: 200%;
        background-image: url('../../../public/svgs/highlight.svg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        z-index: -1;
        // transform: scale(1.2, 1.4);
      }
    }
  }

  .decorations {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;

    .star-1 {
      position: absolute;
      top: 120px;
      left: 127px;
      width: 35px;
      height: 103px;
    }

    .star-2 {
      position: absolute;
      top: 70px;
      left: 170px;
      width: 20px;
      height: 65px;
    }

    .circle {
      position: absolute;
      top: 60px;
      left: calc(100% - 200px);
      width: 170px;
      height: 181px;
    }

    .saas {
      position: absolute;
      top: 330px;
      left: 0px;
      width: 160px;
      height: 182px;
    }

    .non-code {
      position: absolute;
      top: 200px;
      right: 0px;
      width: 160px;
      height: 182px;
    }
  }
}

@media (max-width: 1080px) {
  .introduction-wrapper {
    .content {
      max-width: 564px;
    }

    .hey-there {
      font-size: 30px;
    }

    .name {
      font-size: 120px;
    }

    .description {
      max-width: 564px;
    }
  }
}

// @media (max-width: 950px) {
//   .introduction-wrapper {
//     .content {
//       max-width: 564px;
//     }

//     .hey-there {
//       font-size: 24px;
//     }

//     .name {
//       font-size: 100px;
//     }

//     .description {
//       max-width: 450px;
//     }

//     .decorations {
//       .star-1 {
//         left: 50px;
//       }

//       .star-2 {
//         left: 80px;
//       }

//     }
//   }
// }

@media (max-width: 910px) {
  .introduction-wrapper {
    .content {
      max-width: 500px;
    }

    .hey-there {
      font-size: 24px;
    }

    .name {
      font-size: 100px;
    }

    .description {
      font-size: 20px;
      max-width: 350px;
    }

    .decorations {

      .circle {
        width: 130px;
      }

      .saas {
        width: 120px;
      }

      .non-code {
        width: 120px;
      }
    }
  }
}
@media (max-width: 744px) {
  .introduction-wrapper {
    .content {
      max-width: 500px;
    }

    .hey-there {
      font-size: 24px;
    }

    .name {
      font-size: 100px;
    }

    .description {
      font-size: 20px;
      max-width: 350px;
    }

    .decorations {

      .circle {
        display: none;;
      }

      .saas {
        display: none;
      }

      .non-code {
        display: none;
      }
    }
  }
}
@media (max-width: 500px) {
  .introduction-wrapper {
    .introduction {
      padding: 80px 40px;
    }

    .hey-there {
      font-size: 20px;
      line-height: 45px;
    }

    .name {
      font-size: 70px;
    }

    .description {
      font-size: 16px;
      max-width: 350px;
    }

    .decorations {
      .star-1 {
        left: 20px;
      }

      .star-2 {
        left: 30px;
      }

      .circle {
        display: none;
      }

      .saas {
        display: none;
      }

      .non-code {
        display: none;
      }
    }
  }
}