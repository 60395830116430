@import '../../variables';

.recent-works {
  position: relative;
  text-align: center;
  overflow: hidden;
  margin-bottom: 80px;

  .recent-works-title {
    @include H2;
    color: $primary-color;
    margin: 150px 0px 100px 0px;
  }

  .projects-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    padding: 0 20px;
    max-width: 1200px;
    margin: 0 auto;
  }

  .project-box {
    width: 100%;
    max-width: 548px;
    height: 445px;
    background-color: #fff;
    border: 2px solid #000000;
    margin: 0 auto;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    img {
      width: 100%;
      height: 335px;
      object-fit: scale-down;
      border-bottom: 2px solid #000000;
      display: block;
      display: flex;
      flex: none;
    }

    .project-info {
      padding: 20px 32px 32px 32px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .project-title {
      @include H6;
      margin-bottom: 8px;
    }

    .project-description {
      @include caption3;
      color: #8c8c8c;
    }
  }

  .jane-button {
    width: fit-content;
    height: 58px;
    @include caption3;
    // margin: 30px auto 0;
    margin-top: 10px;
    padding: 10px 30px 10px 30px;
  }

  .sparkle,
  .sparkle2,
  .cloud {
    position: absolute;
  }

  .sparkle {
    width: 104px;
    height: 159px;
  }

  .sparkle2 {
    width: 39px;
    height: 52px;
  }

  .cloud {
    width: 303px;
    height: 241px;
  }

  .sparkle.top-left {
    top: 100px;
    left: 290px;
  }

  .sparkle2.top-right {
    top: 100px;
    left: 1500px;
  }

  .cloud.bottom-right {
    width: 164px;
    height: 130px;
    bottom: 20px;
    right: 40px;
  }

  // Media query for screens less than 1330px
  @media (max-width: 900px) {
    .projects-container {
      grid-template-columns: 1fr;
    }

    .project-box {
      max-width: 100%;
    }
  }
}